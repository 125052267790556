<template>
  <div class='setting-container'>
    <!--    header -->
    <b-row>
        <b-col cols="12">
          <div class="setting-title">
            <h4 class="setting-title-text">プロフィール</h4>
          </div>
        </b-col>
      </b-row>
<!--    dialog info account login -->
    <div class="content-setting px-2 pt-1 mt-2">
      <validation-observer ref="simpleRulesUpdate"  v-slot="{invalid}">
      <b-form class="mt-2" @submit.prevent>
        <validation-provider
            #default="{ errors }"
            name="メールアドレス"
        >
          <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="2"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="メールアドレス"
              label-for="user_id"
          >
            <b-form-input  :class="{'inputChange' :statusForm,'errors__validated' : errors[0] }" :disabled="true" placeholder="ユーザーIDを入力" v-model="adminStore.login_id" size="sm" id="user_id"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
            #default="{ errors }"
            name="ユーザー名"
            vid="name"
        >
          <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="2"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="ユーザー名"
              label-for="user_name"
          >
            <b-form-input :disabled="statusForm" :class="{'inputChange' :statusForm,'errors__validated' : errors[0] }" placeholder="ユーザー名を入力" v-model="adminStore.name" size="sm" id="user_name"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
            v-if="showSelectRole"
            #default="{ errors }"
            name="管理者区分"
            vid="role_id"
            as="select">
          <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="2"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label="管理者区分"
          >
            <v-select
                :disabled="adminStore.role_type[0] === STAFF_ADMIN"
                v-model="getRoleIsUser"
                :clearable="false"
                label="text"
                :options="options"
                @input="changeGetIdRole(getRoleIsUser)"
                :class="errors[0] ? 'errors__validated' : ''">
            <template #no-options>
              検索条件に一致するデータはありません。
            </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <b-form-group
            v-if="!showSelectRole"
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            label="管理者区分"
        >
          <p class="account-role"  v-for="role in options.filter(role=>adminStore.role_id.indexOf(role.value)!== -1)">
            {{ role.text }}
          </p>
        </b-form-group>
        <validation-provider
            #default="{ errors }"
            name="パスワード"
            vid="password"
            v-if="showSelectRole"
        >
          <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              content-cols-sm
              content-cols-lg="10"
              label-for="password" label="パスワード">
            <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : ''"
            >
              <b-form-input
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  :class="{'inputChange' :statusForm, 'errors__validated' : errors[0]}"  placeholder="パスワード" v-model="passwordUpdate" size="sm" id="password"
              />
              <b-input-group-append is-text>
                <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <div class="footer-modal-wrap footer-modal mt-3">
          <b-button v-if="!showSelectRole"  class="btn-ok"   @click="changeFormUpdateAcc">編集</b-button>
          <b-button v-if="!statusForm" class="btn-cancer" @click="cancerUpdate">キャンセル</b-button>
          <b-button v-if="showSelectRole" class="btn-ok" :disabled="invalid" @click="updateUser">更新</b-button>
        </div>
      </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {BRow,BCol, BButton, BFormGroup, BFormInput, BInputGroup,BFormSelect, BInputGroupAppend,BForm} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { max, required} from "@validations";
import { mapState } from "vuex";
import {apiService} from "@/services/api.service";
import {STATUS, SYSTEM_ADMIN, STAFF_ADMIN} from "@/constant/constant";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import {togglePasswordVisibility} from "@core/mixins/ui/forms";
import {nextTick} from "@vue/composition-api";
import store from "@/store";
export default {
  name: 'Setting',
  props: [],
  components:{
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BForm,
    BInputGroupAppend,
    BFormSelect,
    ValidationObserver,
    ValidationProvider
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      options: [],
      statusForm:true,
      disabledInput:true,
      required,
      max,
      infoAccount:null,
      showSelectRole:false,
      getRoleIsUser:'',
      passwordUpdate:'',
      userRole: null,
      isRoleAdmin : '',
      cloneInfoAcc : null,
      STAFF_ADMIN
    }
  },
  mounted() {
    this.userRole = this.$store.state.app.admin.role_type
  },
  computed: {
    ...mapState({
      adminStore: state => state.app.admin,
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  methods: {
    changeFormUpdateAcc(){
      this.userRole.forEach(item => {
          this.disabledInput = !this.disabledInput
          this.statusForm = ! this.statusForm
          // reformat the role as value:text after receiving the role tu api
          this.showSelectRole = ! this.showSelectRole;
          if (this.showSelectRole){
            // when you press cancel ( do not update anymore, the role will have to be unchanged, so you have to create // add 1 variable to avoid changing the role.
            this.getRoleIsUser  = this.options.filter(role=>this.adminStore.role_id.indexOf(role.value)!== -1)
          }
      })
    },
    async updateUser(n){
      this.startLoading();
      this.$refs.simpleRulesUpdate.reset();
      try {
        // if showSelectRole ie user opens form input. Click once again to enter the update state -> proceed to update the data
        if (this.showSelectRole){
          let dataRoleUpdate = Object.assign({},this.adminStore);
          if (this.getRoleIsUser){
            let adminRole = [];
            if (Array.isArray(this.getRoleIsUser)){
              adminRole.push(this.getRoleIsUser[0]?.value)
              dataRoleUpdate.role_id = adminRole;
            }else {
              adminRole.push(this.getRoleIsUser?.value);
              dataRoleUpdate.role_id = adminRole;
            }
          }
          if (this.passwordUpdate) dataRoleUpdate.password = this.passwordUpdate;
          let res = await apiService.put(`/manage-admin/${dataRoleUpdate.id}`, dataRoleUpdate)
          if (res.data.code === STATUS.SUCCESS) {
            this.notifySuccess(STATUS.MESSENGER_UPDATE_SUCCESS);
            await localStorage.setItem('user',JSON.stringify(res.data.data));
            await this.$store.dispatch('app/setAdminInfo')
            // check if role has === SYSTEM_ADMIN-> if k = it will hide update button
            nextTick((n)=>{
                this.isRoleAdmin = this.adminStore.role_type[0] === SYSTEM_ADMIN;
              // only when roleAdmin != System_admin will reload the page;
              // when the role changes -> reload the page to handle middeware
                if (!this.isRoleAdmin){
                  this.$router.go();
                }
            });

            this.changeFormUpdateAcc();
            this.passwordUpdate = '';
            this.passwordFieldType = 'password';
          } else if (res.data.code === STATUS.BAD_REQUEST) {
            this.$refs.simpleRulesUpdate.setErrors(res.data.data);
          } else this.notifyError(res.data.message);
        }
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
      }
    },
    cancerUpdate(){
      this.$refs.simpleRulesUpdate.reset();
      this.isRoleAdmin = ! this.isRoleAdmin;
      this.disabledInput = !this.disabledInput;
      this.statusForm = ! this.statusForm;
      this.showSelectRole = ! this.showSelectRole;
      this.passwordUpdate = '';
      this.passwordFieldType = 'password';
      // put the data back to the way it was if you press cancer
      // (recall commit set admin info again)
      store.commit('app/SET_ADMIN_INFO', {...this.cloneInfoAcc})
    },
    async getRoles(){
      this.startLoading();
      try {
        let res = await apiService.get('/get-role/');
        let roles = res.data.data;
        if (res.data.code === STATUS.SUCCESS){
          roles.forEach(role=>{
            let listRole = {
              value: role?.id,
              text: role?.role_name
            }
            this.options.push(listRole);
          })
        }else{
          this.notifyError(res.data.message);
        }
      }catch (e){
        this.notifyError(e.data.message);
      }finally {
        this.endLoading();
      }
    },
    changeGetIdRole(roles){
      if (roles){
        return roles.value;
      }
    },
  },
  created() {
    this.getRoles();
    this.isRoleAdmin = (this.adminStore.role_type[0] === SYSTEM_ADMIN);
    //Make a copy so that when cancer does not update the data will be back to the way it was
    this.cloneInfoAcc = {...this.adminStore};
  }
}
</script>

<style lang='scss' scoped>
.content-setting{
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.setting-title{
  font-size: 16px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  .setting-title-text{
    color: #6F767E;
  }
}
.setting-title::after{
  display: block;
  content: "";
  background-color: #377DFF;
  width: 100px;
  height: 2px;
}
.footer-modal-wrap {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  .btn-cancer{
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-right: 10px;
    padding-top: 9px;
    border: 1px solid #EDEDED !important;
  }
  .btn-ok{
    background-color: #377DFF!important;
    border: none;
  }
}
.inputChange{
  border: none;
  background-color: transparent;
  color:#000000;
}
.icon-show-password{
  position: relative;
  top: 8px;
}
.account-role{
  padding-left: 10px;
}
.form-control.is-invalid{
  background-image: none;
}
.role-noti {
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  color: red;
}
</style>
